import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div className='container-fluid p-0'>
                <div className='footer row d-flex justify-content-center'>
                    <div className='col-lg-2 col-md-6 col-sm-6 col-12 footer-links'>
                        <ul>
                            <Link to="/"><li>Home</li></Link>
                            <Link to="/company"><li>Company</li></Link>
                            <Link to="/howwework"><li>How We Work</li></Link>
                            <Link to="/expertise"><li>Expertise</li></Link>
                            <Link to="/associates"><li>Associates</li></Link>
                        </ul>
                    </div>  
                    <div className='col-lg-2 col-md-6 col-sm-6 col-12 footer-links'>
                        <ul>
                            <Link to="/projects"><li>Projects</li></Link>
                            <Link to="/distributors"> <li>Distributors</li></Link>
                            <Link to="/faq"><li>FAQ</li></Link>
                            <Link to="/"> <li>Careers</li></Link>
                            <Link to="/contactus"> <li>Contact Us</li></Link>
                        </ul>
                    </div>
                    <div className='col-lg-2 col-md-4 col-sm-12 col-12 footer-para'>
                        <p>Acoustical Surfaces, Inc.</p>
                        <p>123 Columbia Court North,</p>
                        <p>Suite 201 Chaska,</p>
                        <p>MN 55318-USA.</p>
                        <p>Tel : (952)-448-5300</p>
                        <p>Fax : (952)-448-2613</p>
                        <p>info@starhomecinema.com</p>
                    </div>
                    <br />
                    <div className='col-lg-2 col-md-4 col-sm-12 col-12 footer-para'>
                        <p>Acoustical Surfaces Industries</p>
                        <p>130/2B, Morappampalayam,</p>
                        <p>Uppilipalayam (P.O)</p>
                        <p>Karuvalur ,Tirupur - 641670 ,</p>
                        <p>India</p>
                        <p>Mob : +91 8277331000</p>
                        <p>info@starhomecinema.com</p>
                    </div>
                    <div className='col-lg-2 col-md-4 col-sm-12 col-12 footer-para'>
                        <p>Moov Group.</p>
                        <p>Office No# B2002</p>
                        <p>Latifa Tower</p>
                        <p>Sheikh Zayed Road,</p>
                        <p>P.O.Box: 113239, Dubai - UAE.</p>
                        <p>Tel:+971 4331 0000</p>
                        <p>info@starhomecinema.com</p>
                    </div>
                </div>
            </div>

            <div className='copyrights'>
                <p>© 2025 | www.starhomecinema.com | All Rights Resevered.</p>
            </div>
        </>
    )
}

export default Footer