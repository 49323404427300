import React, { useEffect } from 'react';
import '../Homepage/home.css';
import brand1 from '../../images/brand1.png';
import brand2 from '../../images/brand2.png';
import brand3 from '../../images/brand3.png';
import brand4 from '../../images/brand4.png';
import brand5 from '../../images/brand5.png';
import brand6 from '../../images/brand-moov.png';
import Navbar from '../../Common/Navbar/Navbar';
import Slider from '../../Common/slider/Slider';
import Footer from '../../Common/Footer/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Home Theater Acoustics: Best Practices for Immersive Audio</title>
                <meta name="description" content="We are the Contractor of Home Cinema Acoustics and Soundproofing Movie Theater at Home" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Home Cinema Contractor | Home Theater Themes and Soundproofing" />
                <meta property="og:description" content="" />
                <meta property="og:locale" content="en-ae" />
                <meta name="geo.placename" content="Dubai" />
                <meta http-equiv="content-language" content="ae" />
                <meta name="geo.region" content="Dubai" />
                <link rel="canonical" href="https://www.starhomecinema.com/" />
                <link rel="alternate" href="https://www.starhomecinema.com/" hreflang="en-ae" />
            </Helmet>
            <section id='home'>
                <Navbar />
                <Slider />

                <div className='container home-container w-60'>
                    <div className='row home-content'>
                        <h2>WELCOME TO STAR HOME CINEMA</h2>
                        <div className='col-md-7 col-12 mt-3'>
                            <p>A cinema is an escape from reality. It is a place to go to for entertainment, for enjoyment and for relaxation. It is full of treasured moments with loved ones and a place to rub elbows with the stars. Let us bring these elements into your home with your very own, personalized dedicated home cinema. We offer the finest home theater for a no-ny taste and budget. Our home cinema seats with cup holders, rear illuminated poster cases and marquees, home theater popcorn machines, authentic theater signs, movie theme sconce lights, novelties, home theater automation systems and custom items are all designed to enhance your movie viewing pleasure.</p>
                        </div>
                        <div className='col-md-5 col-12 d-flex justify-content-center align-items-center'>
                            <img src="	https://www.starhomecinema.com/images/projects/project23.jpg" alt="welcomestarthomecinema" />
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='row home-content'>
                        <div className='col-md-7 col-12 order-md-2'>
                            <h2>Home Theater</h2>
                            <p>In terms of design, your Home Theater is the most versatile room in your home. Your options are limitless. You can compliment your existing home decor or you can create a design that is completely different from the rest of your home.</p>
                            <p>If you enjoy exploring unique design opportunities, our decorative acoustic panels can act as a canvas for your inspiration.</p>
                            <p>Perhaps, you prefer a theme for your Home Theater Design. We design <Link to='/company' className='a-tag'>acoustic home theaters</Link> based on various requirements.</p>
                            <p>Call us today to discuss any of your Home Theater design and installations and ask about our complimentary design services.</p>
                        </div>

                        <div className='col-md-5 col-12 order-md-1 d-flex justify-content-center align-items-center'>
                            <img src="https://www.starhomecinema.com/images/img1.jpg" alt="welcomestarthomecinema" />
                        </div>

                    </div>
                    <div className='borders-bottom'></div>

                    <div className='row home-content'>
                        <div className='col-md-7 col-12'>
                            <h2>Your Personal Consultant</h2>
                            <p>The moment you contact Star Home Cinema, we will guide you throughout the entire process of <Link to='/expertise' className='a-tag'>designing</Link> your Home Theater. An effective home theater showcases your individuality. It is, well, very human. The customization process requires a relationship that is both professional and personal.</p>
                            <p>Sometimes it will only take a few emails or phone conversations to finalize a design. Other times, it may take a great deal of time to design your ultimate Home Theater. Either way, will patiently work with you until you are 100% satisfied with your final design concept.</p>
                        </div>
                        <div className='col-md-5 col-12 d-flex justify-content-center align-items-center'>
                            <img src="https://www.starhomecinema.com/images/img2.jpg" alt="welcomestarthomecinema" />
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='home-content p-2'>
                        <h2 className='col-md-12 col-12 d-flex justify-content-center align-items-center'>5 Reasons to Choose Star Home Cinema For Your Home Theater</h2>
                        <p><span> 1 - Experience is Everything - </span> We have worked on many Home Theater Designs and installations ranging from Traditional styles to modern Space Themes. We will transform your unique vision into a custom Home Theater that you will be proud to share with your friends and family. When people walk into your custom home theater, they will know it belongs to you.</p>
                        <p><span> 2 - One Size Does Not Fit All - </span> Your theater has it's own unique configuration. We analyze your room to determine the appropriate size and location of each acoustic panel. This results in a finished Home Theater that will sound great and look even better. To maximize your theaters potential, the room must be looked at by a home theater professional.</p>
                        <p><span> 3 - Think Outside The Box - </span> Do you want to create a theme for your Home Theater? We can create or obtain any resource needed to deliver your special theater. Maximizing the home theater experience entails more than just technical details, it requires an understanding of aesthetics and personal taste.</p>
                        <p><span> 4 - Acoustic Matters - </span> A typical <Link to='contactus' className='a-tag'>acoustic panel</Link> is quite necessary and extremely important part in any home cinema. Our Acoustic Consultant will be coordinating in the entire process of design and installation of your Home Theater. When contemplating your custom home theater, think Acoustic, because Star Home Cinema thinks Acoustic.</p>
                        <p><span> 5 - Seeing is Believing - </span> We offer a free Photo Realistic 3-D Rendering of your Home Theater. The rendering will help you visualize (and realize) the potential of your theater. The renderings also help us to determine the proper size, quantity and location of the acoustic panels.</p>
                        <p>Customizing a home theater is similar to building a house. There's a thorough process to it, including numerous renderings if needed. In other words, you will never be left in the dark and informed at every step. The rendering will help you evaluate your custom design and make any necessary changes to ensure that you are 100% satisfied with your final theater concept. After all, this is your Home Theater.</p>
                    </div>


                    <div className='row brand'>
                        <div className='brand-img col-md-2 col-sm-4 col-6'>
                            <img src={brand1} alt="brand-logo" className="img-fluid" />
                        </div>
                        <div className='brand-img col-md-2 col-sm-4 col-6'>
                            <img src={brand2} alt="brand-logo" className="img-fluid" />
                        </div>
                        <div className='brand-img col-md-2 col-sm-4 col-6'>
                            <img src={brand3} alt="brand-logo" className="img-fluid" />
                        </div>
                        <div className='brand-img col-md-2 col-sm-4 col-6'>
                            <img src={brand4} alt="brand-logo" className="img-fluid" />
                        </div>
                        <div className='brand-img col-md-2 col-sm-4 col-6'>
                            <img src={brand5} alt="brand-logo" className="img-fluid" />
                        </div>
                        <div className='brand-img col-md-2 col-sm-4 col-6'>
                            <img src={brand6} alt="brand-logo" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default Home